import { Carousel } from 'antd';
import { CardsServices } from './cardsServices';
import styles from './stylesCatalog.module.scss';

export function Catalog() {
    const currenWhidth = window.innerWidth;

    const contentCards = [
        {
            title: `Desenvolvimento de Aplicativos Mobile`,
            description: 
            <>
                Desenvolvemos <span style={{ color: '#2AADF0'}}>aplicativos móveis para iOS e Android</span> que oferecem uma experiência de usuário excepcional e funcionalidades avançadas. <br />
                Nossos aplicativos são projetados para <span style={{ color: '#2AADF0'}}>atender às necessidades específicas do seu negócio</span> e ajudar a alcançar seus objetivos.
            </>,
            sourceColor: '#2AADF0'
        },
        {
            title: `Desenvolvimento de Software Sob Medida`,
            description: 
            <>
                Na LevaTech Solutions, criamos software sob medida para resolver problemas específicos e <span style={{ color: '#1EE4BB '}}>melhorar a eficiência operacional do seu negócio</span>. <br /> Nossa <span style={{ color: '#1EE4BB' }}>abordagem personalizada</span> garante que o software seja perfeitamente ajustado às suas necessidades.
            </>,
            sourceColor: '#1EE4BB'
        },
        {
            title: 'Desenvolvimento de Sites e E-commerce',
            description: 
            <>
                Criamos sites e plataformas de e-commerce com <span style={{ color: '#2AADF0' }}>design moderno e funcionalidades avançadas</span>, que não só atraem visitantes, mas também os convertem em clientes. <br /> <span style={{ color: '#2AADF0' }}>Focamos na experiência do usuário e na otimização para motores de busca (SEO)</span> para garantir o sucesso online do seu negócio.
            </>,
            sourceColor: '#2AADF0'
        },
    ]
    return (
        <div className={styles.container}>
            <div className={styles.boxTitle}>
                <h1>Serviços</h1>
                <p>
                    Na LevaTech Solutions, oferecemos uma variedade de serviços para atender às suas necessidades tecnológicas. 
                    Nosso foco é criar soluções personalizadas que agreguem valor e impulsionem o sucesso do seu negócio.
                </p>
            </div>
            <div className={styles.contentCards}>
                {currenWhidth > 1024 && 
                contentCards.map((content, index) => (
                        <CardsServices
                            key={index}
                            title={content.title}
                            description={content.description}
                            sourceColor={content.sourceColor}
                        />
                    ))    
                }
                {currenWhidth <= 1024 &&
                    <Carousel 
                        autoplay
                    >
                        {contentCards.map((content, index) => (
                            <CardsServices
                                key={index}
                                title={content.title}
                                description={content.description}
                                sourceColor={content.sourceColor}
                            />
                        ))}
                    </Carousel>
                }
            </div>
        </div>
    )
}