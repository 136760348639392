import { Button, Col, Form, Input, notification, Spin } from 'antd';
import { FormProps } from 'antd/lib';

import { ContactAPI } from '../../configs/api/contact';
import { NotificationType } from "../../types/commonTypes";

import faleConoscoImg from '../../assets/images/img-fale-conosco/img-fale-conosco@2x.png'

import styles from './contactStyle.module.scss';
import { useState } from 'react';

type FieldType = {
    name: string;
    email: string;
    phone: string;
    company: string;
    subject: string;
    message: string;
}

export function Contact() {
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const openNotificationWithIcon = (
        type: NotificationType,
        title: string,
        description: string
      ) => {
        api[type]({
          message: title,
          description: description,
        });
      };

      // Limpa máscara numéricas, removendo tudo exceto números
    function clearStringOnlyNumbers(value: string | number) {
        return String(value).replace(/\D/g, "");
    }
    // Máscara de telefone fixo e celular com DDD
    function inputMaskTELWithDDD(value: string) {
        if (!value) {
          return value;
        }
        let mask = clearStringOnlyNumbers(value);
    
        if (mask.length > 11) {
          mask = mask.substring(0, 11);
        }
    
        if (mask.length <= 10) {
          mask = mask.replace(/(\d{2})(\d)/, "($1) $2");
          mask = mask.replace(/(\d)(\d{4})$/, "$1-$2");
        } else {
          mask = mask.replace(/(\d{2})(\d)/, "($1) $2");
          mask = mask.replace(/(\d{1})(\d{4})/, "$1 $2");
          mask = mask.replace(/(\d{4})(\d{4})$/, "$1-$2");
        }
    
        return mask;
    }

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        const contactService = new ContactAPI();
        setLoading(true)
        try {
            await contactService.sendContact({
                name: values.name,
                email: values.email,
                phone_number: clearStringOnlyNumbers(values.phone),
                company: values.company,
                subject: values.subject,
                description: values.message,
            });
            openNotificationWithIcon(
                'success',
                'Sucesso!',
                'Contato enviado com sucesso.'
            )
            form.setFieldsValue({
                name: '',
                email: '',
                phone: '',
                company: '',
                subject: '',
                description: ''
            })
            setLoading(false)
        } catch (error: any) {
            openNotificationWithIcon(
                'error',
                'Erro!',
                `${error.message}` ?? 'Ocorreu um erro desconhecido.'
            )   
            setLoading(false)     
        }
    };

    return (
        <div className={styles.container}>
            {contextHolder}
            <Spin spinning={loading} fullscreen />
            <div className={styles.contentForm}>
                <h1>Contato</h1>
                <p>
                    Entre em contato conosco para saber mais sobre nossos serviços e como podemos ajudar a impulsionar o sucesso do seu negócio.
                </p>
                <Form
                    form={form}
                    name="layout-multiple-horizontal"
                    layout="inline"
                    style={{
                        marginTop: '2rem',
                        width: '100%',
                        rowGap: '1rem'
                    }}
                    onFinish={onFinish}
                >
                    <Col
                        span={24}
                    >
                        <Form.Item
                            layout="vertical"
                            label="Nome Completo"
                            name="name"
                            rules={[{ required: true }]}
                        >
                            <Input 
                                placeholder='Digite seu nome completo'
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={12}
                    >
                        <Form.Item
                            layout="vertical"
                            name="email"
                            label="E-mail"
                            rules={[
                            {
                                type: 'email',
                                message: 'O e-mail não é válido!',
                            },
                            {
                                required: true,
                                message: 'Por favor, digite seu e-mail!',
                            },
                            ]}
                        >
                            <Input 
                                placeholder='Digite seu e-mail'
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={12}
                    >
                        <Form.Item
                            layout="vertical"
                            name="phone"
                            label="Telefone"
                            rules={[{ 
                                required: true, 
                                message: 'Por favor, digite seu telefone!',
                            }]}
                        >
                            <Input 
                                placeholder='Digite seu telefone'
                                onChange={(e) =>
                                    form.setFieldsValue({
                                        phone: inputMaskTELWithDDD(e.target.value),
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={12}
                    >
                        <Form.Item
                            layout="vertical"
                            name="company"
                            label="Empresa"
                            rules={[{ required: false }]}
                        >
                            <Input 
                                placeholder='Digite o nome da sua empresa'
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={12}
                    >
                        <Form.Item
                            layout="vertical"
                            name="subject"
                            label="Assunto"
                            rules={[{ required: true }]}
                        >
                            <Input 
                                placeholder='Digite o assunto'
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={24}
                    >
                        <Form.Item
                            layout="vertical"
                            name="message"
                            label="Mensagem"
                            rules={[{ required: true }]}
                        >
                            <Input.TextArea 
                                placeholder='Digite sua mensagem'
                                rows={6}
                                showCount
                                maxLength={500}
                                style={{
                                    borderRadius: '2rem',
                                    color: '#FFF',
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        span={24}
                        >
                        <Form.Item>
                            <Button
                                size='large'
                                htmlType='submit'
                                shape='round'
                            >
                                Enviar contato
                            </Button>
                        </Form.Item>
                    </Col>
                </Form>
            </div>
            <div className={styles.boxImg}>
                <img src={faleConoscoImg} alt="" />
            </div>
        </div>
    )
}