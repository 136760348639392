import React, { act, useState } from 'react';
import { Button, ConfigProvider, Row, Tabs, TabsProps } from 'antd';
import nossaOrigem from '../../assets/images/img-origem/img-origem@2x.png'
import nossaMissão from '../../assets/images/img-missão/img-missão@2x.png'
import nossaValores from '../../assets/images/img-valores (1)/img-valores@2x.png'
import marcadoresSvg from'../../assets/images/bullet-vector/bullet-vector@2x.png'

import styles from './aboutUs.module.scss';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

interface TabContentProps {
    text: string | JSX.Element;
    subTitle?: string | JSX.Element;
}

export function AboutUsComponent() {
    const currentWidth = window.innerWidth;
    const [activeKey, setActiveKey] = useState('1');
    const onChange = (key: string) => {
        setActiveKey(key);
    };

    const colorByKey: any = {
        '1': '#29BE9F',
        '2': '#0F6BCA',
        '3': '#2AADF0',
    };

    function TabDescription({text, key }: {text: string, key?: string }) {

        return (
            <p 
                className={(activeKey === key) ? styles.active : styles.tabText}
                style={(activeKey === key) ? {color: colorByKey[key]} : {color: null}}
            >
                {text}
            </p>
        );
    }

    function TabContent({text, title}: {text: string | JSX.Element, title?: string}) {
        return (
            <div className={styles.tabContent}>
                <Subtitle text={title ?? ''} />
                <p>{text}</p>
            </div>
        );
    }

    function SpanColor({text, color}: {text: string, color?: string}) {
        return (
            <span style={{color: color}}>{text}</span>
        );
    }

    function TabContentList({items}: {items: TabContentProps[]}) {
        return (
            <div className={styles.tabList}>
                <h2>A base de tudo que fazemos</h2>
                <h3>Nossos valores são <SpanColor text='a base de tudo o que fazemos' color='#2AADF0'/> na <SpanColor text='LevaTech Solutions' color='#2AADF0'/>:</h3>
                {items.map((item, index) => (
                    <div key={index} className={styles.contentList}>
                        <div className={styles.boxMarcador}>
                            <img src={marcadoresSvg} alt="marcadores" />
                            <p>{item.subTitle}</p>
                        </div>
                        <p className={styles.text}>
                            {item.text}
                        </p>
                    </div>
                ))}
            </div>
        );
    }

    function Subtitle({text}: {text: string}) {
        return (
            <h2>{text}</h2>
        );
    }

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: TabDescription({text: 'Origem', key: '1' }),
          children: TabContent({text: 
            <>
                A LevaTech Solutions foi fundada em {<SpanColor text='Campo Grande' color='var(--water-green-500)'/>}, Mato Grosso do Sul, por {<SpanColor text='Leandro de Brito Gonçalves' color='var(--water-green-500)'/>}, um desenvolvedor de software {<SpanColor text='experiente' color='var(--water-green-500)'/>} com uma paixão {<SpanColor text='por tecnologia e inovação' color='var(--water-green-500)'/>}. 
                <br />
                <br />
                Desde o início, nossa missão tem sido transformar {<SpanColor text='ideias em realidade' color='var(--water-green-500)'/>}, oferecendo soluções tecnológicas personalizadas e de alta qualidade. A empresa nasceu da vontade de criar um {<SpanColor text='impacto positivo no mundo digital' color='var(--water-green-500)'/>}, ajudando empresas a crescer e prosperar na era da tecnologia.
            </>,
            title: 'Conheça noossa origem'
          }),
          //@ts-ignore
          sourceColor: '#29BE9F',
        },
        {
          key: '2',
          label: TabDescription({text: 'Missão', key: '2' }),
          children: TabContent({text: 
            <>
                Na LevaTech Solutions, nossa missão é fornecer {<SpanColor text='soluções tecnológicas inovadoras' color='#0F6BCA'/>} e <SpanColor text='personalizadas' color='#0F6BCA'/> que ajudem nossos clientes a alcançar seus objetivos e <SpanColor text='transformar suas ideias em realidade' color='#0F6BCA'/>.
                <br />
                <br />
                Estamos comprometidos com a {<SpanColor text='excelência' color='#0F6BCA' />}, a {<SpanColor text='integridade' color='#0F6BCA' />} e a {<SpanColor text='inovação contínua' color='#0F6BCA' />}, oferecendo serviços de desenvolvimento de software, sites e aplicativos mobile que superem as expectativas.
            </>,
            title: 'Transformando ideias em realidade'
            }),
             //@ts-ignore
            sourceColor: '#0F6BCA',
        },
        {
          key: '3',
          label: TabDescription({text: 'Valores', key: '3'}),
          children: TabContentList({items: [
            {
                text: 'Buscamos constantemente novas ideias e soluções criativas para atender às necessidades dos nossos clientes.',
                subTitle: 'INOVAÇÃO',
            },
            {
                text: 'Comprometemo-nos a entregar produtos e serviços de alta qualidade que agreguem valor real aos nossos clientes.',
                subTitle: 'EXCELÊNCIA',  
            },
            {
                text: 'Valorizamos a honestidade e a comunicação aberta em todas as nossas interações.',
                subTitle: 'TRANSPARÊNCIA',
            },
            {
                text: 'Acreditamos no poder do trabalho em equipe e na colaboração com nossos clientes para alcançar os melhores resultados.',
                subTitle: 'COLABORAÇÃO',
            },
            {
                text: 'Estamos comprometidos em operar de maneira sustentável, respeitando o meio ambiente e promovendo práticas éticas.',
                subTitle: 'SUSTENTABILIDADE',
            }
          ]}, 
        ),
         //@ts-ignore
        sourceColor: '#0F6BCA',
        },
    ];

    return (
        <ConfigProvider
          theme={{
            token: {
                colorPrimary: `${colorByKey[activeKey]}`,
                colorText: '#FFFFFF',
                fontFamily: 'IBM Plex Sans, sans-serif',
                fontSize: '0.85rem',
            } as any,
          }}
        >
            <div className={styles.contentCarouselComponent}>
                <div className={styles.container}>
                    <h1>Sobre nós</h1>
                    <div className={styles.boxCarousel}>
                        <Tabs 
                            tabPosition={currentWidth <= 768 ? 'top' : 'left'}
                            defaultActiveKey="1" 
                            items={items} 
                            onChange={onChange}      
                            activeKey={activeKey}                   
                        />
                        <Row 
                            style={{
                                gap: '1rem',
                                marginTop: '1rem',
                                marginLeft: 'auto',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button 
                                style={{
                                    border: `1px solid ${colorByKey[activeKey]}`,
                                    backgroundColor: Number(activeKey) > 1 ? '' : 'transparent',
                                    color: `${Number(activeKey) > 1 ? '#FFFFFF' : colorByKey[activeKey]}`,
                                }}
                                disabled={Number(activeKey) === 1}
                                type={Number(activeKey) > 1 ? 'primary' : 'default'}
                                shape="circle" 
                                icon={<ArrowLeftOutlined />} 
                                onClick={() => {
                                    let newKey = Number(activeKey) - 1;
                                    setActiveKey(newKey.toString());}
                                }
                            />
                            <Button 
                                style={{
                                    border: `1px solid ${colorByKey[activeKey]}`,
                                    backgroundColor: Number(activeKey) < 3 ? '' : 'transparent',
                                    color: `${Number(activeKey) < 3 ? '#FFFFFF' : colorByKey[activeKey]}`,
                                }}
                                disabled={Number(activeKey) === 3}
                                type={Number(activeKey) < 3 ? 'primary' : 'default'}
                                shape="circle" 
                                icon={<ArrowRightOutlined />} 
                                onClick={() => {
                                    let newKey = Number(activeKey) + 1;
                                    setActiveKey(newKey.toString());
                                    }
                                }
                            />
                        </Row>
                    </div>
                </div>
                {(activeKey === '1') && 
                <div className={styles.boxImages}>
                    <img src={nossaOrigem} alt="img" />
                </div>}
                {(activeKey === '2') &&
                <div className={styles.boxImages}>
                    <img src={nossaMissão} alt="img" />
                </div>
                }
                {(activeKey === '3') &&
                <div className={styles.boxImages}>
                    <img src={nossaValores} alt="img" />
                </div>
                }
            </div>
        </ConfigProvider>
    );
}