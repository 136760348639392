import { Result } from "antd";
import { Contact } from "../../components/contact/contact";
import { CatalogComponent } from "../../components/catalog/catalogComponent";

export function ServicePage() {
    return (
        <>
            <CatalogComponent />
            <Contact />
        </>
    );
}