import { Button, ButtonProps } from "antd";

interface CustomBtnProps {
    text: string;
    onClick: () => void;
    size: "small" | "middle" | "large";
}


interface CustomBtnProps {
    text: string;
    onClick: () => void;
    size: "small" | "middle" | "large";
}

export function CustomBtn({ 
    text, 
    onClick,
    size = "middle",
    ...props
 }: CustomBtnProps & ButtonProps) {
  return (
    <Button 
        shape="round" 
        size={size}
        onClick={onClick}
        {...props}
    >
      {text}
    </Button>
  );
}