import { Card } from 'antd';

import styles from './stylesCatalog.module.scss';
import { CustomBtn } from '../custonBtn/custonBtn';

interface ICardProps {
    title: string;
    description: string | JSX.Element;
    sourceColor?: string;
    index?: number;
}

export function CardsServices({
    title,
    description,
    sourceColor,
    index
}: ICardProps) {
    return (
        <Card 
            style={{ width: 300 }}
            className={
                styles.cardServices
            }
        >
            <p 
                className={styles.titleCard}
                style={{ color: sourceColor }}
            >
                {title}
            </p>
            <span
                className={styles.descriptionCard}
            >
                {description}
            </span>
            <div className={styles.boxBtn}>
                <CustomBtn 
                    text='Saiba mais'
                    onClick={() => console.log('Clicou no botão')}
                    size='large'
                    style={{
                        height: 54,
                        fontSize: '1rem',
                    }}
                />
            </div>
        </Card>
    )
}
