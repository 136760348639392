import { Card } from 'antd';
import { CustomBtn } from '../custonBtn/custonBtn';

import styles from './stylesCatalog.module.scss';

interface ICardProps {
    title: string;
    description: string | JSX.Element;
    sourceColor?: string;
    index?: number;
    textPosition?: string;
    bGGradiente?: string;
}

export function CardsServicesContainer({
    title,
    description,
    sourceColor,
    index,
    textPosition,
    bGGradiente
}: ICardProps) {
    return (
        <Card 
            style={{ 
                width: '100%', 
                background: bGGradiente ? bGGradiente : 'linear-gradient(74deg, #0F6BCA 0%, #05294E 100%)'
            }}
            className={
                styles.cardServicesComponent
            }
        >
            <p 
                className={styles.titleCardComponent}
                style={{ 
                    color: '#FFFFFF', 
                    display: 'flex',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    justifyContent: textPosition === 'left' ? 'flex-start' : 'flex-end',
                }}
            >
                {title}
            </p>
            <span
                className={styles.descriptionCard}
            >
                {description}
            </span>
            <div 
                className={styles.boxBtnComponent}
                style={{
                    left: textPosition === 'left' ? '0.75rem' : 'auto',
                    right: textPosition === 'right' ? '0.75rem' : 'auto',
                }}
            >
                <CustomBtn 
                    text='Saiba mais'
                    onClick={() => console.log('Clicou no botão')}
                    size='large'
                    style={{
                        height: 54,
                        fontSize: '1rem',
                    }}
                />
            </div>
        </Card>
    )
}
