import { Carousel } from 'antd';
import { CardsServicesContainer } from './cardsServicesContainer';

import appDevelopmentImg from '../../assets/images/Português (1).png';
import CRMImg from '../../assets/images/Dark Mode Modern Project Management Software Desktop Prototype.png';
import ecomerceImg from '../../assets/images/Orange E-commerce Online Store Logo.png';
import { CardsServices } from './cardsServices';

import styles from './stylesCatalog.module.scss';

export function CatalogComponent() {
    const currenWhidth = window.innerWidth;

    const contentCards = [
        {
            title: `Desenvolvimento de Aplicativos Mobile`,
            description: 
            <div className={styles.contentDescriptiom}>
                <div className={styles.boxDescription}>
                    Desenvolvemos {<span style={{ fontWeight: 'bold' }}>aplicativos móveis personalizados para iOS e Android</span>} que oferecem uma {<span style={{ fontWeight: 'bold' }}>experiência</span>} de usuário  
                    {<span style={{ fontWeight: 'bold'  }}> excepcional</span>} e funcionalidades avançadas.
                    Nossos aplicativos são projetados para atender às necessidades específicas do seu negócio e ajudar a alcançar seus 
                    objetivos. 
                    <br />
                    <br />
                    Utilizamos as mais recentes tecnologias e metodologias ágeis para {<span style={{ fontWeight: 'bold' }}> garantir que seu aplicativo esteja sempre 
                    atualizado</span>} e atenda às expectativas dos usuários finais. Suportamos plataformas como {<span style={{ fontWeight: 'bold' }}>iOS e Android</span>}, e nossas 
                    soluções incluem {<span style={{ fontWeight: 'bold' }}>funcionalidades avançadas</span>} como integração com APIs, notificações push, geolocalização e 
                    autenticação segura. 
                    <br />
                    <br />
                    Utilizamos tecnologias como Flutter, React Native, Swift e Kotlin, e seguimos metodologias 
                    ágeis como Scrum e Kanban para assegurar um {<span style={{ fontWeight: 'bold' }}>processo de desenvolvimento eficiente e colaborativo</span>}, desde a concepção 
                    e design até o desenvolvimento, testes e lançamento na App Store e Google Play.
                </div>
                <div className={styles.boxImg}>
                    <img src={appDevelopmentImg} alt="Imagem de uma equipe de desenvolvimento trabalhando, escrito desenvolvimento de aplicativos móveis, e perguntando pronto para criar se aplicativo" />
                </div>
            </div>,
            sourceColor: '#2AADF0',
            textPosition: 'left',
            bGGradiente: 'linear-gradient(74deg, #0F6BCA 0%, #05294E 100%)'
        },
        {
            title: `Desenvolvimento de Software Sob Medida`,
            description: 
            <div className={styles.contentDescriptiom}>
                <div className={styles.boxImg}>
                    <img src={CRMImg} alt="Imagem da tela de um sistema de gestão" />
                </div>
                <div className={styles.boxDescription}>
                    Na LevaTech Solutions, criamos software sob medida para resolver problemas específicos e {<span style={{ fontWeight: 'bold' }}>melhorar a 
                    eficiência operacional do seu negócio</span>}. Nossa abordagem personalizada garante que o software seja 
                    perfeitamente ajustado às suas necessidades, {<span style={{ fontWeight: 'bold' }}>proporcionando uma solução única e eficaz</span>}. 
                    <br />
                    <br />
                    Trabalhamos em {<span style={{ fontWeight: 'bold' }}>estreita colaboração com nossos clientes</span>} para entender seus desafios e objetivos, desenvolvendo 
                     {<span style={{ fontWeight: 'bold' }}> soluções</span>} que realmente {<span style={{ fontWeight: 'bold' }}>fazem</span>} a {<span style={{ fontWeight: 'bold' }}>diferença</span>}. 
                    <br />
                    <br />
                    Desenvolvemos diversos tipos de software, como {<span style={{ fontWeight: 'bold' }}>sistemas de gestão</span>}, {<span style={{ fontWeight: 'bold' }}>CRM</span>}, {<span style={{ fontWeight: 'bold' }}>ERP</span>} e soluções de {<span style={{ fontWeight: 'bold' }}>automação de 
                    processos</span>}, utilizando tecnologias como Node.js, Python, Java e .NET. Nosso processo de desenvolvimento inclui 
                    análise de requisitos, design, desenvolvimento, testes, implementação e suporte contínuo, seguindo metodologias 
                    como Scrum, Kanban ou Waterfall, dependendo do projeto. Os benefícios incluem {<span style={{ fontWeight: 'bold' }}>soluções personalizadas que 
                    aumentam a eficiência</span>} e melhoram a integração com sistemas existentes.
                </div>
            </div>,
            sourceColor: '#1EE4BB',
            textPosition: 'right',
            bGGradiente: 'linear-gradient(74deg, #1EE4BB 0%, #05745D 100%)'
        },
        {
            title: 'Desenvolvimento de Sites e E-commerce',
            description: 
            <div className={styles.contentDescriptiom}>
                <div className={styles.boxDescription}>
                Criamos sites e plataformas de e-commerce com {<span style={{ fontWeight: 'bold' }}>design moderno e funcionalidades avançadas</span>}, que não só atraem 
                visitantes, mas também os convertem em clientes. Focamos na experiência do usuário e na otimização para motores 
                de busca (SEO) para {<span style={{ fontWeight: 'bold' }}>garantir o sucesso online do seu negócio</span>}. 
                <br />
                <br />
                Desenvolvemos diversos tipos de sites, incluindo {<span style={{ fontWeight: 'bold' }}>institucionais</span>}, {<span style={{ fontWeight: 'bold' }}>portfólios</span>}, {<span style={{ fontWeight: 'bold' }}>blogs</span>} e {<span style={{ fontWeight: 'bold' }}>plataformas de e-commerce</span>}, 
                com funcionalidades avançadas como carrinho de compras, sistema de pagamento, integração com redes sociais e chat 
                ao vivo. Utilizamos tecnologias como HTML5, CSS3, JavaScript, React, e WordPress, e nossos serviços 
                incluem planejamento, design, desenvolvimento, testes, lançamento e manutenção contínua, assegurando que seu site 
                esteja sempre atualizado e otimizado para SEO, performance e acessibilidade.
                </div>
                <div className={styles.boxImg}>
                    <img src={ecomerceImg} alt="Imagem de um e-comerce" />
                </div>
            </div>,
            sourceColor: '#2AADF0',
            textPosition: 'left',
            bGGradiente: 'linear-gradient(285deg, #0F6BCA 0%, #05294E 100%)'
        },
    ]

    const contentCardsResponsive = [
        {
            title: `Desenvolvimento de Aplicativos Mobile`,
            description: 
            <>
                Desenvolvemos <span style={{ color: '#2AADF0'}}>aplicativos móveis para iOS e Android</span> que oferecem uma experiência de usuário excepcional e funcionalidades avançadas. <br />
                Nossos aplicativos são projetados para <span style={{ color: '#2AADF0'}}>atender às necessidades específicas do seu negócio</span> e ajudar a alcançar seus objetivos.
            </>,
            sourceColor: '#2AADF0'
        },
        {
            title: `Desenvolvimento de Software Sob Medida`,
            description: 
            <>
                Na LevaTech Solutions, criamos software sob medida para resolver problemas específicos e <span style={{ color: '#1EE4BB '}}>melhorar a eficiência operacional do seu negócio</span>. <br /> Nossa <span style={{ color: '#1EE4BB' }}>abordagem personalizada</span> garante que o software seja perfeitamente ajustado às suas necessidades.
            </>,
            sourceColor: '#1EE4BB'
        },
        {
            title: 'Desenvolvimento de Sites e E-commerce',
            description: 
            <>
                Criamos sites e plataformas de e-commerce com <span style={{ color: '#2AADF0' }}>design moderno e funcionalidades avançadas</span>, que não só atraem visitantes, mas também os convertem em clientes. <br /> <span style={{ color: '#2AADF0' }}>Focamos na experiência do usuário e na otimização para motores de busca (SEO)</span> para garantir o sucesso online do seu negócio.
            </>,
            sourceColor: '#2AADF0'
        },
    ]
    return (
        <div className={styles.containerComponent}>
            <div className={styles.boxTitleComponent}>
                <h1>Serviços</h1>
                <p>
                    Na LevaTech Solutions, oferecemos uma variedade de serviços para atender às suas necessidades tecnológicas. 
                    Nosso foco é criar soluções personalizadas que agreguem valor e impulsionem o sucesso do seu negócio.
                </p>
            </div>
            {currenWhidth > 425 && <div className={styles.contentCardsComponent}>
                {contentCards.map((content, index) => (
                        <CardsServicesContainer
                            key={index}
                            title={content.title}
                            description={content.description}
                            sourceColor={content.sourceColor}
                            textPosition={content.textPosition}
                            bGGradiente={content.bGGradiente}
                        />
                    ))    
                }
            </div>}
            {(currenWhidth <= 425) &&
                <div>
                        <Carousel 
                            autoplay
                            style={{
                                margin: '0 auto',
                                width: '100%',
                                height: '100%',
                                zIndex: 5,
                                padding: '2rem',
                            }}
                        >
                            {contentCardsResponsive.map((content, index) => (
                                <CardsServices
                                    key={index}
                                    title={content.title}
                                    description={content.description}
                                    sourceColor={content.sourceColor}
                                />
                            ))}
                        </Carousel>
                </div>
            }
        </div>
    )
}