import { Routes, Route } from 'react-router-dom';

import { Home } from '../pages/home';
import { About } from '../pages/about/about';
import { ServicePage } from '../pages/servicePage/servicePage';
import { ContactPage } from '../pages/contact/contact';
import Layout from '../components/layout/layout';

function Router() {
  return (
    <Routes>
      <Route path='/' element={<Layout />} >
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path='/services' element={<ServicePage />} />
        <Route path='/contact' element={<ContactPage />} />
      </Route>
    </Routes>
  );
}

export default Router;

