import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from "./routes/route";
import { ConfigProvider } from 'antd';

import ptBR from "antd/locale/pt_BR";

function App() {
  return (
    <BrowserRouter>
      <ConfigProvider
        locale={ptBR}
        theme={{
          token: {
            fontFamily: 'IBM Plex Sans, sans-serif',
            fontSize: 14,
            borderRadius: 50,
            colorBorder: '#FFFFFF',
            controlHeight: 50,
            colorTextPlaceholder: '#FFFFFF',
            colorTextTertiary: '#FFFFFF',
          },
          components: {
            Input: {
              colorBgContainer: '#000000',
              colorPrimary: '#FFFFFF',
              colorPrimaryHover: '#FFFFFF',
              colorPrimaryText: '#FFFFFF',
              colorText: '#FFFFFF',
            },
          },
        }}
      >
        <Router />
      </ConfigProvider> 
    </BrowserRouter>
  );
}

export default App;
