import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomBtn } from '../custonBtn/custonBtn';
import { MenuDrawer } from '../menuDrawer/menuDrawer';

import LogoLevaTechImg from '../../assets/images/Leva Tech Solutions - Logo 01 - Horizontal - Negativo.png';

import styles from './headerStyle.module.scss';
import { MenuOutlined } from '@ant-design/icons';

export function Header() {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const dimensions = window.innerWidth;
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <img src={LogoLevaTechImg} alt="Logo" />
      </div>
      {dimensions <= 768 &&
        <MenuDrawer 
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      }
      {dimensions > 768 && 
      <>
        <nav className={styles.navBar}>
          <a 
          href="/"
          itemID={currentPath === '/' ? 'true' : 'false'}
          >
              Home
          </a>
          <a 
            href="/about"
            itemID={currentPath === '/about' ? 'true' : 'false'}
          >
            Sobre Nós
          </a>
          <a 
            href="/services"
            itemID={currentPath === '/services' ? 'true' : 'false'}
          >
            Serviços
          </a>
          <a 
            href="/contact"
            itemID={currentPath === '/contact' ? 'true' : 'false'}
          >
            Fale Conosco
          </a>
        </nav>
        <CustomBtn 
          text="Contato" 
          onClick={() => {
            navigate('/contact');
          }} 
          size="large" 
          style={{
            right: '2rem',
            width: '10rem',
            height: '3.5rem',
            fontSize: '1.25rem',
          }}
        />
      </>
      }
      {dimensions <= 768 &&
        <MenuOutlined 
          className={styles.menuIcon}
          onClick={() => setOpenDrawer(true)}
        />
      }
    </header>
  );
}