import { Drawer, Menu } from "antd";
import { useNavigate } from "react-router-dom";

import { AccountBookOutlined, AppstoreAddOutlined, AppstoreOutlined, HomeOutlined, InfoCircleOutlined, MailOutlined, SettingOutlined } from "@ant-design/icons";
import type { MenuProps } from 'antd';

import LogoLevaTechImg from '../../assets/images/Leva Tech Solutions - Logo 01 - Horizontal - Negativo.png';

import styles from './menuStyles.module.scss';

interface MenuDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
}

export function MenuDrawer({
  openDrawer,
  setOpenDrawer,
}: MenuDrawerProps) {
  const navigate = useNavigate();

  type MenuItem = Required<MenuProps>['items'][number];

  const items: MenuItem[] = [
    {
      key: '/',
      label: 'Home',
      icon: <HomeOutlined />,
    },
    {
      key: '/about',
      label: 'Sobre nós',
      icon: <InfoCircleOutlined />
    },
    {
      key: '/services',
      label: 'Serviços',
      icon: <AppstoreAddOutlined />
    },
    {
      key: '/contact',
      label: 'Contato',
      icon: <MailOutlined />
    },
  ];

  const Title = () => {
    return (
        <div className={styles.logo}>
            <img src={LogoLevaTechImg} alt="Logo" />
        </div>
    );
  }

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key as string);
    setOpenDrawer(false);
  };

  return (
    <Drawer
      title={<Title />}
      placement='right'
      closable={true}
      onClose={() => setOpenDrawer(false)}
      open={openDrawer}
      key='right'
      styles={{
        body: {
            backgroundColor: '#001529',
        },
        content: {
            backgroundColor: '#001529',
            color: '#fff',
        }
      }}
    >
        <Menu
            onClick={onClick}
            //style={{ width: 256 }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={items}
            theme="dark"
        />
    </Drawer>
  );
}