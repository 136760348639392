import { api } from "./api";

interface IContactData {
    name: string;
    email: string;
    phone_number: string;
    company?: string;
    subject: string;
    description: string;
}

export class ContactAPI {
    async sendContact(data: IContactData) {
        return await api.post('/levaTech/contact', data);
    }
}