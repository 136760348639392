import { FacebookFilled, InstagramOutlined, LinkedinFilled } from "@ant-design/icons";
import { CustomBtn } from "../../components/custonBtn/custonBtn";
import { Header } from "../../components/header/header"

import styles from './home.module.scss';
import { AboutUs } from "../../components/aboutUs/aboutUs";
import { Catalog } from "../../components/catalog/catalog";
import { Contact } from "../../components/contact/contact";

export function Home() {
  return (
    <>
      <div className={styles.home}>
          <h1>Transformando ideias em realidade</h1>
          <p>Somos uma empresa de software. Trabalhamos na construção de ferramentas de impacto, inovação e alta tecnologia.</p>
          <CustomBtn 
            text="Saiba mais" 
            onClick={() => {
              window.location.href = '/about';
            }} 
            size="large" 
            style={{
              backgroundColor: '#000',
              color: '#fff',
              border: 'none',
              marginTop: '2rem',
              marginLeft: '1rem',
              width: '12rem',
              height: '3.5rem',
              fontSize: '1.25rem',
            }}
          />
          <div 
            className={styles.socialMedia}
          >
            <a href="https://www.linkedin.com/in/leandro-de-brito-gon%C3%A7alves-540232ba/" target="_blanc">
              <LinkedinFilled />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61562464151183" target="_blanc">
              <FacebookFilled />
            </a>
            <a href="https://www.instagram.com/levatechsolution?igsh=MXhwOHkzOXNqa3kxMw==" target="_blanc">
              <InstagramOutlined /> 
            </a>
          </div>
      </div>
      <AboutUs />
      <Catalog />
      <Contact />
    </>   
  );
}