import { Contact } from "../../components/contact/contact";
import { AboutUsComponent } from "../../components/aboutUs/aboutUsComponent";

export function About() {
    return (
        <>
            <AboutUsComponent />
            <Contact />
        </>
    );
}