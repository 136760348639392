import { Col, Divider, List, Row } from "antd";
import { ArrowUpOutlined, FacebookFilled, InstagramOutlined, LinkedinFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import styles from './footerStyle.module.scss';
import { CustomBtn } from "../custonBtn/custonBtn";

import iconImg from '../../assets/images/Leva Tech Solutions - Logo 02 - Ícone.png';

export function Footer() {
    const navigate = useNavigate();
    const currentWidth = window.innerWidth;

    const data = [
        {
          title: 'Página Inicial',
          list: [
            {
              title: 'Home',
            },
            {
              title: 'Sobre Nós',
            },
            {
              title: 'Serviços',
            },
            {
              title: 'Fale Conosco',
            },
          ]
        },
        {
          title: 'Sobre Nós',
            list: [
                {
                    title: 'Origem',
                },
                {
                    title: 'Missão',
                },
                {
                    title: 'Valores',
                },
            ]
        },
        {
          title: 'Serviços',
            list: [
                {
                    title: 'Desenvolvimento de Aplicativos Mobile',
                },
                {
                    title: 'Desenvolvimento de Software Sob Medida',
                },
                {
                    title: 'Desenvolvimento de Sites e E-commerce',
                },
            ]
        },
        {
          title: 'Contato',
            list: [
                {
                    title: 'Fale Conosco',
                },
            ]
        },
      ];

      // Função para rolar até o topo da página
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Faz a rolagem ser suave
        });
    };
      
    return (
        <div className={styles.containerFooter}>
            <div className={styles.listFooter}>
                <List
                    grid={{ gutter: 14, column: 4 }}
                    dataSource={data}
                    style={{
                        width: currentWidth > 768 ? '80%' : '100%',
                    }}
                    renderItem={(item) => (
                    <List.Item>
                        <Row>
                            <Col span={4}>
                                <p>{item.title}</p>
                                {currentWidth > 450 && 
                                <ul>
                                    {item.list.map((listItem) => (
                                        <li>
                                            <a href="">
                                                {listItem.title.replace('Desenvolvimento de', '')}
                                            </a>
                                        </li>
                                    ))}
                                </ul>}
                            </Col>
                        </Row>
                    </List.Item>
                    )}
                />
                <CustomBtn 
                    text="Voltar ao Topo"
                    onClick={() => {
                        scrollToTop()
                    }}
                    size="large"
                    icon={<ArrowUpOutlined />}
                    style={{
                        width: (currentWidth > 450) ? '25%' : (currentWidth > 768) ? '15%' : '40%',
                        height: '3rem',
                    }}
                />
            </div>
            <Divider 
                style={{
                    backgroundColor: '#fff',
                    margin: '2rem 0',
                }}
            />
            <div className={styles.boxFinal}>
                <p>© 2024 Leva Tech Solutions. Todos os direitos reservados.</p>
                <div className={styles.boxImg}>
                    <img src={iconImg} alt="Logo da Leva Tech Solutions" />
                </div>
                <div className={styles.boxMedia}>
                    <div 
                        className={styles.socialMediaFooter}
                    >
                        <a href="https://www.linkedin.com/in/leandro-de-brito-gon%C3%A7alves-540232ba/" target="_blanc">
                        <LinkedinFilled />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61562464151183" target="_blanc">
                        <FacebookFilled />
                        </a>
                        <a href="https://www.instagram.com/levatechsolution?igsh=MXhwOHkzOXNqa3kxMw==" target="_blanc">
                            <InstagramOutlined />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}